<template>
  <Alerta />
  <div class="views">
    <component :is="layout" />
  </div>
</template>

<script setup>
import { computed, inject, onMounted, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreAlerta, useStorePerfil } from '@stores'
import { helperBloqueioBody, helperRedirectBrowser } from '@helpers'
import Alerta from '@components/global/alerta/Alerta.vue'

const { currentRoute } = useRouter()
const router = useRouter()

const emitter = inject('emitter')
const storePerfil = useStorePerfil()
const storeAlerta = useStoreAlerta()

const layout = computed(() => {
  if (!currentRoute?.value?.name) return
  return currentRoute?.value.meta.layout || 'default-layout'
})

function mostrarAlerta(payload) {
  const dados = {
    visivel: true,
    mensagem: payload.mensagem,
    classe: payload.tipo
  }
  storeAlerta.mostrarAlerta(dados)
}

function detectarModalAberto() {
  setInterval(() => helperBloqueioBody.bloquearBodyQuandoAbrirModal(), 500)
}

function redirecionarBrowser() {
  helperRedirectBrowser.redirectAndroidInAppBrowsers()
}

function receberTema() {
  const dadosLocalStorage = JSON.parse(localStorage.getItem('personalizacao-unicpages'))
  const tema = dadosLocalStorage?.tema || 'escuro'

  if (!dadosLocalStorage) {
    localStorage.setItem('personalizacao-unicpages', JSON.stringify({ tema: 'escuro' }))
    document.body.setAttribute('tema', 'escuro')
    return
  }

  document.body.setAttribute('tema', tema)
}

function shouldCheckSubscription() {
  const excludedUrls = ['/', '/registrar', '/criar-conta', '/erro']
  const currentUrl = window.location.pathname
  return !excludedUrls.includes(currentUrl)
}

async function verificarAssinatura() {
  if (!shouldCheckSubscription()) return

  await storePerfil.receberPerfilUsuario()
  const assinatura = storePerfil.usuario?.assinatura
  const assinaturaOk = assinatura?.ativo === true

  if (!assinaturaOk) {
    router.push('/reativar')
  }
}

onMounted(() => {
  detectarModalAberto()
  verificarAssinatura()
  emitter.on('mostrarAlerta', (payload) => mostrarAlerta(payload))
})

onBeforeMount(() => {
  receberTema()
  redirecionarBrowser()
})
</script>
