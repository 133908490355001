export default [
  {
    path: '/',
    name: 'entrar',
    component: () => import('../components/autenticacao/entrar/Entrar.vue'),
    meta: {
      title: 'Entre em sua conta Unic',
      layout: 'default-layout'
    }
  },
  {
    path: '/registrar',
    name: 'registrar',
    component: () => import('../components/autenticacao/registrar/Registrar.vue'),
    meta: {
      title: 'Crie sua conta na UnicPages',
      layout: 'default-layout'
    }
  },
  {
    path: '/criar-conta',
    name: 'criarConta',
    component: () => import('../components/autenticacao/criarConta/CriarConta.vue'),
    meta: {
      title: 'Crie sua conta na UnicPages',
      layout: 'default-layout'
    }
  },
]
