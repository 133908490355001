// vue & pinia
import { createApp } from 'vue'
import { createPinia } from 'pinia'

// app & router
import App from './App.vue'
import router from './router'

// css global
import './css/global.css'

// layouts
import DefaultLayout from './layouts/DefaultLayout.vue'
import NavbarLayout from './layouts/NavbarLayout.vue'

// libs
import mitt from 'mitt'
import VueNumberFormat from 'vue-number-format'
import VueTheMask from 'vue-the-mask'
import Particles from "particles.vue3"

// diretivas personalizadas
import { autoRedimensionarTextArea, limitarCaracteres } from './directives'

// instâncias & configurações

const configVueNumberFormat = {
  prefix: '',
  decimal: ',',
  thounsand: '.',
  isInteger: false
}

const app = createApp(App)
const emitter = mitt()

app.use(router)
  .use(createPinia())
  .use(VueTheMask)
  .use(VueNumberFormat, configVueNumberFormat)
  .use(Particles)
  .provide('emitter', emitter)
  .directive("redimensionar-textarea", autoRedimensionarTextArea)
  .directive("limitar-caracteres", limitarCaracteres)
  .component('default-layout', DefaultLayout)
  .component('navbar-layout', NavbarLayout)
  .mount('#app')
